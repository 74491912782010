<template>
<div style="line-height:60px;font-size: 12px;display: flex;">
    <div style="flex:1;font-size: 30px;" >
      <span :class="collapseBtnClass" style="cursor: pointer" @click="collapse"></span>
    </div>
    <el-dropdown style="width: 20px;cursor: pointer">
      <i class="el-icon-setting" ></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>个人信息</el-dropdown-item>
        <el-dropdown-item>
          <router-link to="/login" style="text-decoration: none">
            退出系统
          </router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <span> {{ user.username }}</span>
</div>
</template>
<style>

</style>
<script>
import request from "@/utils/request";

export default {
  name: "Head",
  props:{
    collapseBtnClass:String,
    collapse:Function
  },
  data(){
    return {
      user:{}
    }
  },
  created() {
    let userStr = sessionStorage.getItem("user")||"{}"
    this.user=JSON.parse(userStr)
  }
}
</script>

<style scoped>

</style>