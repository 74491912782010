import axios from 'axios'
import router from "@/router";

const request = axios.create({
    //baseURL: 'http://localhost:80/zhihuiyun',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    baseURL: 'https://hddiancan.cloud/zhihuiyun',
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
// request.interceptors.request.use(config => {
//     config.headers['Content-Type'] = 'application/json;charset=utf-8';
//
//     config.headers['token'] = user.token;  // 设置请求头
//     let userJson = sessionStorage.getItem("user")
//     if(!userJson){
//        router.push("/login")
//     }
//
//     return config
//
// }, error => {
//     return Promise.reject(error)
// });



request.interceptors.request.use(config => {
    // config.headers['Content-Type'] = 'application/json;charset=utf-8';

    // 从 sessionStorage 获取用户信息
    let userJson = sessionStorage.getItem("user");
    if (!userJson) {
        // 检查是否已经在登录页，避免重复导航
        if (router.currentRoute.path !== "/login") {
            router.push("/login").catch(err => {
                // 在这里处理错误，通常是忽略 NavigationDuplicated 的错误
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
                // 可选：你可以在这里记录或做一些其他处理
            });
        }
    } else {
        // 解析 JSON 字符串以获取用户对象
        let user = JSON.parse(userJson);
        // 设置 token
        config.headers['token'] = user.token;
    }
    // 动态设置 Content-Type，避免在发送 multipart/form-data 时覆盖 Content-Type
    if (!config.headers['Content-Type'] && !(config.data instanceof FormData)) {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request

