<template>
  <el-menu :default-openeds="['1']" style="height: 100%;overflow-x: hidden;border-right-color: transparent"
           background-color="rgb(48,65,86)"
           text-color="#fff"
           active-text-color="#ffd04b"
           :collapse-transition="false"
           :collapse="isCollapse"
           router
  >
    <div style="height: 60px;line-height: 60px;text-align: center">
      <img src="../assets/logo-gx.png" style="width: 20px;position: relative;top:5px;margin-right: 5px">
      <b style="color: white" v-show="!isCollapse">国信淮电</b>
    </div>
    <el-menu-item index="/">

        <i class="el-icon-goods"></i>
        <span slot="title">首页</span>

    </el-menu-item>
    <el-submenu index="1">
      <template slot="title">
        <i class="el-icon-phone"></i>
        <span slot="title">随手拍</span>
      </template>
      <!-- 隐患列表及其子菜单 -->
      <el-submenu index="1-1">
        <template slot="title">
          <i class="el-icon-link"></i>
          <span slot="title">隐患列表</span>
        </template>
        <el-menu-item index="/danger">
          <i class="el-icon-document"></i>
          <span slot="title">全部</span>
        </el-menu-item>
        <el-menu-item index="/danger_pendingConfirm">
          <i class="el-icon-document"></i>
          <span slot="title">待确认</span>
        </el-menu-item>
        <el-menu-item index="/danger_deptConfirm">
          <i class="el-icon-document-checked"></i>
          <span slot="title">部门确认</span>
        </el-menu-item>
        <el-menu-item index="/danger_pendingProcessing">
          <i class="el-icon-document-copy"></i>
          <span slot="title">待处理</span>
        </el-menu-item>
        <el-menu-item index="/danger_pendingAcceptance">
          <i class="el-icon-document-checked"></i>
          <span slot="title">待验收</span>
        </el-menu-item>
        <el-menu-item index="/danger_completed">
          <i class="el-icon-check"></i>
          <span slot="title">已完成</span>
        </el-menu-item>
        <el-menu-item index="/danger_rejected">
          <i class="el-icon-close"></i>
          <span slot="title">未通过</span>
        </el-menu-item>
      </el-submenu>
    <el-menu-item index="/order">
      <i class="el-icon-s-custom"></i>
      <span slot="title">支付列表</span>
    </el-menu-item>
    <el-menu-item index="/hangup">
      <i class="el-icon-s-data"></i>
      <span slot="title">挂起列表</span>
    </el-menu-item>
    <el-menu-item index="/dangerType">
      <i class="el-icon-s-data"></i>
      <span slot="title">类型维护</span>
    </el-menu-item>
    </el-submenu>
    <el-submenu index="2">
      <template slot="title">
        <i class="el-icon-s-comment"></i>
        <span slot="title">合理化建议</span>
      </template>
    <el-menu-item index="/advice">
        <i class="el-icon-link"></i>
        <span slot="title">建议列表</span>
    </el-menu-item>
    </el-submenu>
<!--    <el-menu-item index="/practice">-->
<!--      <i class="el-icon-edit"></i>-->
<!--      <span slot="title">自主练习</span>-->
<!--    </el-menu-item>-->
    <el-menu-item index="/wenzhang">
      <i class="el-icon-s-finance"></i>
      <span slot="title">公众号文章</span>
    </el-menu-item>
    <el-submenu index="3">
      <template slot="title">
        <i class="el-icon-video-camera"></i>
        <span slot="title">实操培训</span>
      </template>
      <el-menu-item index="/video">
        <i class="el-icon-video-play"></i>
        <span slot="title">视频列表</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="4">
      <template slot="title">
        <i class="el-icon-alarm-clock"></i>
        <span slot="title">安全园地</span>
      </template>
      <el-menu-item index="/safeStudy">
        <i class="el-icon-link"></i>
        <span slot="title">安全学习</span>
      </el-menu-item>
      <el-menu-item index="/safeList">
        <i class="el-icon-link"></i>
        <span slot="title">安全责任</span>
      </el-menu-item>
      <el-menu-item index="/safeCheck">
        <i class="el-icon-link"></i>
        <span slot="title">安全检查</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="5">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">系统管理</span>
      </template>
      <el-menu-item index="/user">
        <i class="el-icon-s-custom"></i>
        <span slot="title">用户管理</span>
      </el-menu-item>
      <el-menu-item index="/dept">
        <i class="el-icon-s-shop"></i>
        <span slot="title">部门管理</span>
      </el-menu-item>
      <el-menu-item index="/position">
        <i class="el-icon-s-platform"></i>
        <span slot="title">岗位管理</span>
      </el-menu-item>
      <el-menu-item index="/loginLog">
        <i class="el-icon-location"></i>
        <span slot="title">登陆日志</span>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: "Aside",
  props:{
    isCollapse:Boolean
  }
}
</script>

<style scoped>

</style>