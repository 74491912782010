<template>
  <div class="home">
    <el-container >
      <el-aside :width="isCollapse?'64px':'200px'" style="background-color: rgb(238, 241, 246);height: 100vh;
      box-shadow: 2px 0 6px rgb(0 21 41 /35%)">
        <Aside :isCollapse="isCollapse"></Aside>
      </el-aside>
      <el-container>
        <el-header style="border-bottom: 1px solid #cccccc;">
        <Head :collapseBtnClass="collapseBtnClass" :collapse="collapse"> </Head>
        </el-header>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<style>
.el-header {

  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
.home{
  height: 100%;
}
</style>

<script>


import request from "@/utils/request";
import Aside from "@/components/Aside.vue";
import Head from "@/components/Head.vue";
export default {
  data() {
    return {
      tableData: [],
      form:{},
      collapseBtnClass:'el-icon-s-fold',
      isCollapse:false,
      sideWidth:200,
      dialogVisible:false,
      username:"",
      openid:"",
      dept:"",
      pageNum:1,
      pageSize:10,
      total:0,
      headerBg:'headerBg'
    }
  },
  components:{
    Aside,Head
  },

  methods:{
    collapse(){
      this.isCollapse = !this.isCollapse
      if(this.isCollapse){
        this.collapseBtnClass='el-icon-s-unfold'

      }else{
        this.collapseBtnClass='el-icon-s-fold'
      }
    },
  }
};
</script>
<style>
.headerBg{
  background: #cccccc;
}

</style>